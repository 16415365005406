<template>
  <div class="NoFunds">
    <v-container>
      <v-row
        align="center"
        justify="center"
        class="mb-6 pb-2"
      >
        <v-col
          cols="12"
          sm="2"
          md="4"
          lg="4"
        >
          <h1
            class="text-center"
          >
            رصيد غير كافي!
          </h1>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="3"
          md="3"
          lg="3"
        >
          <h3 class="text-right mb-2 pa-2">
            قيمة رصيدك الان:
          </h3>
          <v-text-field
            v-model="balance"
            :loading="loading"
            outlined
            readonly
          />
          <hr>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="2"
          md="3"
          lg="3"
        >
          <v-btn
            x-large
            color="primary"
            :to="{ name: 'charge-funds' }"
            block
          >
            تعبئة رصيد
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    balance: '',
    loading: false,
  }),

  mounted () {
    this.loading = true
    this.axios.get('balance').then(res => {
      this.balance = res.data.data.balance
      this.loading = false
    })
  },
}
</script>
